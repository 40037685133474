import React, { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function BankInfo() {
    const [formData, setFormData] = useState({})

    function getFromData(e) {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    function addBank(e) {
        e.preventDefault()
        if (!formData.branch || !formData.district || !formData.tel || !formData.ifsc_code || !formData.bank_name || !formData.state || !formData.pin) {
            toast.warning('Please fill All Boxes...', { position: "top-center" })
        } else {
            let saveData = JSON.parse(localStorage.getItem('banks'))
            if (saveData) {
                let filter = saveData.filter((item) => item.ifsc_code === formData.ifsc_code || item.branch === formData.branch)
                if (filter.length > 0) {
                    toast.warning("bank is Already Register", { position: "top-center" })
                } else {
                    saveData.push(formData)
                    localStorage.setItem("banks", JSON.stringify(saveData))
                    toast.success("Bank Added Sucessfully", { position: "top-center" })
                    setFormData({})
                    e.target.reset()
                }
            } else {
                let arr = []
                arr.push(formData)
                localStorage.setItem("banks", JSON.stringify(arr))
                toast.success("Bank Added Sucessfully", { position: "top-center" })
                setFormData({})
                e.target.reset()
            }
        }
    }

    return (
        <>
            <div className="container  mt-5">
                <div className="row justify-content-center">
                    <div className="col-6 p-3 shadow bg-primary-subtle">
                        <div className="w-100">
                            <form onSubmit={addBank}>
                                <h3 className="text-center text-dark">Bank Detail</h3>
                                <div className="row mt-3 mb-3">
                                    <div className="col-6">
                                        <input type="text" className="form-control" name='bank_name' placeholder='Bank Name' onChange={getFromData} />
                                    </div>
                                    <div className="col-6">
                                        <input type="text" className="form-control" name='ifsc_code' placeholder='IFSC Code' onChange={getFromData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <select className='form-control' name='branch' onChange={getFromData} >
                                            <option value={undefined}>Choose Branch</option>
                                            <option value="HARDA">HARDA</option>
                                            <option value="MAGARDHA">MAGARDHA</option>
                                            <option value="RAHATGAON">RAHATGAON</option>
                                            <option value="TIMARNI">TIMARNI</option>
                                            <option value="BHIMPUR">BHIMPUR</option>
                                        </select>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" value={formData?.branch} name='post' placeholder='will get it....' onChange={getFromData} readOnly />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" name='district' placeholder='District' onChange={getFromData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" name='state' placeholder='State Eg : MP' onChange={getFromData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="tel" className="form-control" maxLength={6} name='pin' placeholder='Pin Code' onChange={getFromData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="tel" className="form-control" name='tel' placeholder='Tel :' onChange={getFromData}/>
                                    </div>

                                </div>
                                <div className='d-flex justify-content-end gap-4 mt-4'>
                                    <button className='btn btn-outline-success'>Add Bank</button>
                                    <input type="button" value="Reset" className='btn btn-outline-warning' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
