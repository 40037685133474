import React from 'react'

export default function Layout() {
    return (
        <>
            <div className='not-found m-4'>

            </div>
        </>
    )
}
