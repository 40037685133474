import React, { useCallback, useEffect, useRef, useState } from 'react'
import TableComponet from '../componets/table'
import { toast } from 'react-toastify'
import ViewFormat from '../componets/viewFormat'
import { useReactToPrint } from 'react-to-print'

export default function ShowCustomerPage() {
    const [customers, setCustomers] = useState([])
    const [bank, setBank] = useState([])
    const [viewData, setViewData] = useState()
    const [isShow, setIsShow] = useState(false)
    const [isLogoShow, setIsLogoShow] = useState(false)
    const componetRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componetRef.current
    })

    const delCustomer = useCallback(function (obj) {
        const { ele,arr } = obj

        let isDel = window.confirm('Are You Sure ?')
        if (isDel) {
            let newData = arr.filter((item) => item.account_no !== ele.account_no)
            localStorage.setItem('customers', JSON.stringify(newData))
            setCustomers(newData)
            toast.success('customer Deleted Successfully', {
                position: "top-center"
            })
        }
    }, [])

    function viewFormat(data) {
        if (data !== null) {
            setViewData(data)
            setIsShow(!isShow)
        }
    }

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('customers'))
        if (data) {
            setCustomers(data)
        }
        let bank_data = JSON.parse(localStorage.getItem('banks'))
        if (bank_data) {
            setBank(bank_data)
        }
    }, [])

    return (
        <>
            {customers.length ? <>
                <TableComponet data={customers} deleteItem={delCustomer} viewItem={viewFormat} bank={bank} />
                {isShow ? <ViewFormat data={viewData} ref={componetRef} logo={isLogoShow} /> : null}
                {isShow ? <div className='container mt-5'>
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                        <i className="fa-solid fa-xmark fa-lg text-danger" onClick={() => { setIsShow(false) }} style={{ cursor: "pointer" }}></i>
                        <i className="fa-solid fa-print fa-lg text-dark" onClick={handlePrint} style={{ cursor: "pointer" }}></i>
                        <button className={isLogoShow ? "btn btn-outline-warning" : "btn btn-outline-primary"} onClick={() => { setIsLogoShow(!isLogoShow) }}>{isLogoShow ? "Remove Image" : "Add Bank Image"}</button>
                    </div>
                </div> : null}
            </> : <>
                <div className='not-found m-4'>
                    <h4 className='text-center'>No Records....</h4>
                </div>
            </>}
        </>
    )
}
