import React, { useEffect, useState } from 'react'

export default function TableComponet({ data, deleteItem, viewItem, bank }) {
    const [tableKeys, setTableKeys] = useState([])

    useEffect(() => {
        let arr = Object.keys(data?.length && data[0])
        setTableKeys(arr)
    }, [data])

    function eachBank({ ele }) {
        let info = bank?.find((item) => item.branch === ele.branch)
        return info
    }

    return (
        <>
            <div className="container  mt-5">
                <div className="row justify-content-center">
                    <div className="col-12 p-3 shadow bg-primary-subtle overflow-x-auto">
                        <table className='table table-striped w-100'>
                            <thead>
                                <tr>
                                    <th>S No</th>
                                    {tableKeys?.length ? tableKeys.map((item, index) => (
                                        <th key={index}>{item?.toUpperCase()}</th>
                                    )) : null}
                                    <th>OTHER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length ? data.map((ele, index, arr) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {tableKeys.length ? tableKeys?.map((item, idx) => (
                                            <td key={idx}>{ele[`${item}`]}</td>
                                        )) : null}
                                        <td className='text-danger d-flex gap-3 justify-content-evenly align-items-center p-4'>
                                            <i className="fa-solid fa-trash fa-lg" onClick={() => { deleteItem({ ele, arr })}} style={{ cursor: "pointer" }}></i>
                                            <i className="fa-solid fa-eye fa-lg text-primary" onClick={() => { viewItem({ ele, bank_data: eachBank({ ele }) }) }} style={{ cursor: "pointer" }}></i>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
