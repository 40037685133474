import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="w-100 mt-5 foot">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-3 bg-white shadow">
                            <div className="d-flex justify-content-center mt-2">
                                <h5 className="text-dark text-center">All Rights Reserved @2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
