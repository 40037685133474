import React, { useCallback, useEffect, useState } from 'react'
import TableComponet from '../componets/table'
import { toast } from 'react-toastify'

export default function ShowBankPage() {
    const [bankData, setBankData] = useState([])

    const deleteBank = useCallback(function (obj) {
        const { bank_data } = obj
        let isDel = window.confirm('Are You Sure ?')
        if (isDel) {
            // console.log(isDel);
            let newData = bankData?.filter((item) => item.ifsc_code !== bank_data.ifsc_code)
            localStorage.setItem("banks", JSON.stringify(newData))
            setBankData(newData)
            toast.success('customer Deleted Successfully', {
                position: "top-center"
            })
        }
    }, [])

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('banks'))
        if (data && data.length) {
            setBankData(data)
        }
    }, [])

    return (
        <>
            {bankData.length ? <>
                <TableComponet data={bankData && bankData} deleteItem={deleteBank} bank={bankData} />
            </>
                : <div className='not-found m-4'>
                    <h4 className='text-center'>No Records....</h4>
                </div>}
        </>
    )
}
