import React from 'react'
import "./viewFormat.css"

const ViewFormat = React.forwardRef(({ data, logo }, ref) => {

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row justify-content-center">
                    <div className="col-10 p-1 shadow" >
                        <div className='border-1 w-100 row justify-content-center' ref={ref} style={{ fontFamily: "monospace", letterSpacing: "-1px" }}>
                            <div className='col-12 justify-content-center d-flex'>
                                <div className='d-flex'>
                                    {logo ? <div className='overflow-hidden logo-div'>
                                        <img src="images/centralbankofindia-logo.png" className='img-cbi' alt="cbi-logo" />
                                    </div> : null}
                                    <div className={logo ? "branch" : ""}>
                                        <p className='mt-3 p-0 mb-0 text-capitalize'>{data.ele?.branch}</p>
                                        <p className='p-0 mb-0'>Account No:{data.ele?.account_no}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-6 mt-3'>
                                        <div className='ms-5'>
                                            <p className='text-capitalize mb-0'>branch address and tel no.:</p>
                                            <p className='text-capitalize  mb-0'>at post {data.bank_data?.branch}</p>
                                            <p className='text-capitalize mb-0'>dist:{data.bank_data?.district}</p>
                                            {/* State */}<p className='text-capitalize mb-0'>{data.bank_data?.state}</p>
                                            {/* pin number */}<p className='text-capitalize mb-0'>{data.bank_data?.pin}</p>
                                            {/* tel  */}<p className='text-capitalize mb-0'>TEL:{data.bank_data?.tel}</p>
                                            <p className='mb-0 mt-4'>Adharcard Number:{data.ele?.adhar_number}</p>
                                            <p className='mb-0'>IFSC Code:{data.bank_data.ifsc_code}</p>
                                            {/* a/c opning date  */}<p className=''>{data.bank_data.opening_date}</p>
                                        </div>
                                    </div>
                                    <div className='col-6 mt-3'>
                                        <div className='ms-2'>
                                            <p className='text-capitalize mb-0'>name and address of account holder/s:</p>
                                            {/* name */}<p className='mb-0'>{data.ele?.user_name} {data.ele?.gender === "male" ? "S/O" : data.ele?.married === "other" ? "S/O" : data.ele?.married === "married" ? "W/O" : "D/O"} {data.ele?.father_name}</p>
                                            <p className='mb-0 mt-4'>CIF : {data.ele?.cif_no}</p>
                                            <p className='mb-0'>AT VILL-{data.ele?.village}</p>
                                            <p className='mb-0'>TEH:-{data.ele?.tehsil}</p>
                                            <p className='mb-0'>DIST:{data.ele?.district}</p>
                                            <div className='d-flex justify-content-start gap-5'>
                                                {/* pin number */}<p className='mb-0'>{data.ele?.pin}</p><span className='ms-5'>Nomination:{data.ele?.nomini}</span>
                                            </div>
                                            <p className='mb-0'>Toll Free No.:{ }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default ViewFormat;
