import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-3 bg-white shadow">
                        <div className="d-flex justify-content-between">
                            <h2 className="text-success">Passbook Format</h2>
                            <div className='d-flex justify-content-evenly gap-4'>
                                <Link className='nav-link align-content-center text-primary p-2' to="/"><b>Home</b></Link>
                                <Link className='nav-link align-content-center text-primary p-2' to="/add-customer"><b>Add Customer</b></Link>
                                <Link className='nav-link align-content-center text-primary p-2' to="/add-bank"><b>Add Bank</b></Link>
                                <Link className='nav-link align-content-center text-primary p-2' to="/show-customer"><b>Show Customers</b></Link>
                                <Link className='nav-link align-content-center text-primary p-2' to="/show-banks"><b>Show Banks</b></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
