import React from 'react'
import PassbookForm from '../componets/passbookForm'

export default function AddCustomerPage() {
  return (
    <>
    <PassbookForm/>
    </>
  )
}
