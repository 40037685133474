import React from 'react'
import BankInfo from '../componets/bankInfo'

export default function AddBankPage() {
  return (
    <>
    <BankInfo/>
    </>
  )
}
