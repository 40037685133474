import React from 'react'
import Navbar from './componets/navbar'
import Footer from './componets/footer'
import { Route, Routes } from 'react-router-dom'
import AddCustomerPage from './pages/addCustomerPage'
import ShowCustomerPage from './pages/showCustomerPage'
import ShowBankPage from './pages/showBankPage'
import AddBankPage from './pages/addBankPage'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './componets/layout'


export default function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Layout/>} />
        <Route path='/add-customer' element={<AddCustomerPage />} />
        <Route path='/add-bank' element={<AddBankPage />} />
        <Route path='/show-customer' element={<ShowCustomerPage />} />
        <Route path='/show-banks' element={<ShowBankPage />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </>
  )
}
