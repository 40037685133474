import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function PassbookForm() {
    const [userData, setUserData] = useState({})
    const [bankData, setBankData] = useState([])

    function getUserData(e) {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    function saveUser(e) {
        console.log(userData);
        e.preventDefault()
        if (!userData.branch || !userData.village || !userData.user_name || !userData.father_name || !userData.cif_no || !userData.account_no || !userData.pin || !userData.state || !userData.district || !userData.tehsil || !userData.married || !userData.gender || !userData.adhar_number) {
            toast.warning('Please fill All Boxes...', { position: "top-center" })
        } else {
            let saveData = JSON.parse(localStorage.getItem('customers'))
            if (saveData) {
                let filter = saveData.filter((item) => item.account_no === userData.account_no)
                if (filter.length > 0) {
                    toast.warning("customer is Already Register", { position: "top-center" })
                } else {
                    saveData.push(userData)
                    localStorage.setItem("customers", JSON.stringify(saveData))
                    toast.success("customer Added Successfully", { position: "top-center" })
                    setUserData({})
                    e.target.reset()
                }
            } else {
                let arr = []
                arr.push(userData)
                localStorage.setItem("customers", JSON.stringify(arr))
                toast.success("customer Added Sucessfully", { position: "top-center" })
                setUserData({})
                e.target.reset()
            }
        }
    }

    useEffect(() => {
        let bank = JSON.parse(localStorage.getItem('banks'))
        if (bank) {
            setBankData(bank)
        }
    }, [])

    return (
        <>
            <div className="container  mt-5">
                <div className="row justify-content-center">
                    <div className="col-6 p-3 shadow bg-primary-subtle">
                        <div className="w-100">
                            <form onSubmit={saveUser}>
                                <h3 className="text-center text-dark">Customer Detail</h3>
                                <div className="row mt-3 mb-3">
                                    <div className="col-6">
                                        <input type="text" className="form-control" name='user_name' placeholder='A/C Holder Name' onChange={getUserData} />
                                    </div>
                                    <div className="col-6">
                                        <input type="text" className="form-control" name='father_name' placeholder='Father Name' onChange={getUserData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" name='account_no' placeholder='Account Number' onChange={getUserData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" name='cif_no' placeholder='CIF Number' onChange={getUserData} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <select className="form-control" name='nomini' onChange={getUserData} >
                                            <option value="">Choose Nomination</option>
                                            <option value="N">N</option>
                                            <option value="Y">Y</option>
                                        </select>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='village' placeholder='Village' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='post' placeholder='Post' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='tehsil' placeholder='Tehsil' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='district' placeholder='District' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='state' placeholder='State Eg : MP' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='pin' placeholder='Pin Code' />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <select className='form-control' name='branch' onChange={getUserData}>
                                            <option value={undefined}>Choose Branch</option>
                                            {bankData && bankData.map((item, index) => (
                                                <option value={item.branch} key={index}>{item.branch}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <input type="text" className="form-control" onChange={getUserData} name='opening_date' placeholder='A/C Opening Date Eg : 12/12/2012' />
                                    </div>
                                    <div className='col-6 mt-4'>
                                        <select className='form-control' name='gender' onChange={getUserData}>
                                            <option value={undefined}>Choose Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div className='col-6 mt-4'>
                                        <select className='form-control' name='married' onChange={getUserData}>
                                            <option value={undefined}>Choose Marital status </option>
                                            <option value="married">Married</option>
                                            <option value="unmarried">Unmarried</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div className='col-6 mt-4'>
                                        <input type="numbar" className="form-control" onChange={getUserData} name='adhar_number' placeholder='Aadhar Number' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-4 mt-4'>
                                    <button className='btn btn-outline-success'>Add Customer</button>
                                    <input type="button" value="Reset" className='btn btn-outline-warning' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
